
const Equal = 0;
const Not_Equal = 1;
const Equal_To_Not = 2;

let reason = null;

const deep_check_not = (not, object) => {
  if (Array.isArray(not)) {
    for (const item of not) {
      if (deep_rule_equal(item, object) === Equal) {
        return true;
      }
    }
    return false;
  }

  if (deep_rule_equal(not, object) === Equal) {
    console.log("Equal to Not");
    return true;
  }
};

const deep_rule_equal = (rule, object) => {

  // If there is a Not, we need to check that the value doesn't equal that
  if (rule && rule.Not) {
    console.log("Checking Not")
    if (deep_check_not(rule.Not, object)) {
      return Equal_To_Not;
    }
  }

  // If the rule is any, we except everything
  if (rule === "Any") {
    return Equal;
  }

  // If the rule is a range, we check if the object is in that range
  if (rule && rule.Range) {
    console.log(object);
    if (object > rule.Range.Min && object < rule.Range.Max) {
      return Equal;
    }
  }

  if (rule === object) return Equal;

  // If the object is an array then we check if the rule is an array and find if any of the items in the rule match each object in the array
  if (Array.isArray(object)) {
    if (!Array.isArray(rule)) {
      return Not_Equal;
    }

    if (rule.length !== object.length) {
      reason = 'Rule and Object lengths differ';
      return Not_Equal;
    }

    for (const item of object) {
      let found = false;
      for (const rule_item of rule) {
        if (deep_rule_equal(rule_item, item) === Equal) {
          found = true;
          break;
        }
      }

      if (!found) {

        return Not_Equal;
      }
    }

    return Equal;
  }

  // If the rule is an array we accept any of the items in the array
  if (Array.isArray(rule)) {
    for (const item of rule) {
      const result = deep_rule_equal(item, object);
      if (result === Equal) {
        return Equal;
      }
    }

    reason = 'Value not in list of acceptable values'
    return Not_Equal;
  }

  // Check for equality of each key in the rule
  if (typeof rule == "object" && typeof object == "object") {
    if (rule === null) {
      if (object === null) {
        return Equal;
      }
      reason = 'Rule and Object not of same type';
      return Not_Equal;
    }
    if (object === null) {
      if (rule === null) {
        return Equal;
      }
      reason = 'Rule and Object not of same type';
      return Not_Equal;
    }
    // check that the object and rule have the same number of keys, ignoring line, column, and Not
    if (Object.keys(rule).length !== Object.keys(object).length) {
      let dif = 0;
      if (object.hasOwnProperty("line") && object.hasOwnProperty("column")) dif += 2;
      if (object.hasOwnProperty("Text")) dif += 1;
      if (rule.hasOwnProperty("Not")) dif -= 1;

      if (Object.keys(rule).length !== Object.keys(object).length - dif) {
        reason = 'Different number of keys in object';
        return Not_Equal;
      }
    }

    // Check each key in the rule is in the object, ignoring line, column, and Not
    for (const key in rule) {
      if (key === "line" || key === "column" || key === "Not") {
        continue;
      }

      if (!object.hasOwnProperty(key)) {
        reason = `Missing ${key}`;
        return Not_Equal;
      }

      const result = deep_rule_equal(rule[key], object[key]);
      if (result !== Equal) {
        reason = `${key} is incorrect`;
        return result;
      }
    }

    return Equal;
  }

  return Not_Equal;
};

const rule_compare = (rule, object) => {
  reason = null;
  const result = deep_rule_equal(rule, object);

  console.log(result)

  if (result === Equal) {
    return null;
  }

  if (result === Not_Equal) {
    return reason;
  }

  // TODO: figure out what to call this
  if (result === Equal_To_Not) {
    return reason;
  }
};

export {
  deep_rule_equal,
  rule_compare
};