
import { rule_compare } from "./util.js";

const compare_locations = (behavior, rules, errors) => {
  if (behavior.Locations.length !== rules.Locations.length) {
    errors.push({
      type: "Semantic",
      message: `${behavior.Locations.length} locations found, ${rules.Locations.length} expected`,
      line: 0,
      column: 0
    });
    return;
  }

  behavior.Locations.forEach((location) => {
    const rule_location = rules.Locations.find((rule) => {
      return (rule.Name === location.Name);
    });

    if (rule_location === undefined) {
      errors.push({
        type: "Semantic",
        message: `Location ${location.Name} does not match any rules`,
        line: location.line,
        column: location.column,
        text: location.Text
      });
      return;
    }

    const error = rule_compare(rule_location, location);
    if (error) {
      errors.push({
        type: "Semantic",
        message: `Location ${location.Name} ${error}`,
        line: location.line,
        column: location.column,
        text: location.Text
      });
    }
  });
};

export {
  compare_locations
};