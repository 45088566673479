
import './styles/references.css';

export default function References() {
  return (
    <div className={"refDiv"}>
      <div className={"referenceList"}>
        <h2>Reference Links:</h2>
        <li>
          <a
            href={"https://vipra-uwf.github.io/VIPRA/df/d1b/md_docs_2pages_2researchers_2behaviors_2behaviors__syntax.html"}
            rel="noopener"
            target="_blank"
          >
            VIPRA Behaviors Syntax Documentation
          </a>
        </li>
        <li>
          <a href={"https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2"} rel="noopener" target="_blank">
            VIPRA DSL Qualtrics Survey
          </a>
        </li>
      </div>
      <div>
        <small>Version 1.0</small>
      </div>
    </div>
  );
}