
import { rule_compare } from "./util.js";

const compare_events = (behavior, rules, errors) => {
  if (behavior.Events.length !== rules.Events.length) {
    errors.push({
      type: "Semantic",
      message: `${behavior.Events.length} events found, ${rules.Events.length} expected`,
      line: 0,
      column: 0
    });
    return;
  }

  behavior.Events.forEach((event) => {
    const rule_event = rules.Events.find((rule) => {
      return rule.Name === event.Name;
    });

    if (rule_event === undefined) {
      errors.push({
        type: "Semantic",
        message: `Event ${event.Name} does not match any rules`,
        line: event.line,
        column: event.column,
        text: event.Text
      });
      return;
    }

    const error = rule_compare(rule_event, event);
    if (error) {
      errors.push({
        type: "Semantic",
        message: `Event ${event.Name}: ${error}`,
        line: event.line,
        column: event.column,
        text: event.Text
      });
    }
  });

};


export {
  compare_events
};